import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/BuildingBlocks'
import Button from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import Image from '../components/ui/Image'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Font.js'
import OnlineIcon from '../components/ui/OnlineIcon'
import { translate, countryTranslate, activeCountries } from '../../constants/translations'

import Layout from '../components/layout'
import SideMenu from '../components/SideMenu'
import DesktopSideBar from '../components/DesktopSideBar'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'

const FullHeightContainer = styled(Flex)`
  min-height: 100vh;
`

const MainContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 100px;
  padding-top: 0px;
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 700px) {
    border-radius: 32px 32px 0px 0px;
    padding-top: 24px;
    margin-top: -48px;
    background-color: ${colors.lighterGrey};
    position: relative;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.9);
  }
`

const Menu = styled(Flex)`
  height: 60px;
  background-color: ${colors.darkBlue};
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
`

const HeroContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 100px;
  padding-bottom: 0px;
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 700px) {
    padding-top: 60px;
    background-color: ${colors.darkBlue};
    h1 {
      font-size: 28px;
      color: white !important;
    }
    h2 {
      color: white !important;
    }
    h3 {
      color: white !important;
    }
    p {
      color: white !important;
      font-size: 14px;
      line-height: 18px;
    }
    padding-bottom: 60px;
  }
`

const FlagImage = styled.img`
  border-radius: 8px;
  margin-right: 16px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="30px" height="30px" />
}

const LoadingMessage = styled(Flex)`
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  background-color: white;
  padding: 16px;
  height: 80px;
  border-radius: 16px;
  margin-bottom: 16px;
  background-color: white;
  @media (max-width: 700px) {
    flex-direction: column;
    background-color: ${colors.lightGrey};
    background-image: linear-gradient(100deg, ${colors.lightGrey} 0%, #f7f9ff 10%, #f9fbfc 50%, ${colors.lightGrey} 70%);
  }
  position: relative;
  background-image: linear-gradient(100deg, white 0%, #f7f9ff 10%, #f9fbfc 50%, white 70%);
  background-size: 400%;
  animation: loading 0.8s infinite;
  z-index: 1;
`

const Message = styled(Flex)`
  background: white;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
  background-color: white;
  &:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 700px) {
    flex-direction: column;
    background-color: ${colors.lightGrey};
  }
`
const MessagesContainer = styled(Flex)`
  width: auto;
  max-width: 800px;
  flex-direction: column;
`

const MessageText = styled(Text)`
  word-break: break-word;
`

const SenderText = styled(Text)`
  @media (max-width: 600px) {
    margin-top: 10px;
    font-size: 14px;
    line-height: 14px;
  }
`

const DesktopButtonContainer = styled(Flex)`
  @media (max-width: 600px) {
    display: none;
  }
`

const MobileButtonContainer = styled(Flex)`
  display: none;
  @media (max-width: 600px) {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }
`

const BreadCrumbContainer = styled(Flex)`
  padding-left: 100px;
  p {
    font-weight: 300;
  }
  @media (max-width: 1200px) {
    padding-left: 22px;
  }
  @media (max-width: 500px) {
    padding-top: 16px;
    p {
      font-size: 14px;
      color: white;
    }
    span {
      color: white;
      margin-left: 10px;
      margin-right: -6px;
    }
  }
`

const HomeImage = styled(Image)`
  @media (max-width: 500px) {
    display: none;
  }
`

const Li = styled.li`
  display: inline-block;
`

const Ul = styled.ul`
  display: flex;
  align-items: center;
`

const AboutPage = ({}) => {
  return (
    <Layout>
      <Helmet
        title={'About WeReceiveSMS | Exclusive Free Temporary Phone numbers'}
        lang="en"
        description={'Receive SMS online with the webs only exclusive temp numbers. Always free, no signup required.'}
      >
        <html lang={'en'} />
      </Helmet>
      <FullHeightContainer flexDirection="column" alignItems="center">
        <Menu>
          <Link to={`/`}>
            <Flex width="auto" alignItems="flex-end">
              <Image alt="home icon" mr="14px" width="30px" height="30px" src="/home.svg" />
              <Text bold color="white">
                Home
              </Text>
            </Flex>
          </Link>
          <Text color="white">
            <Span color="white" fontSize="26px" fontWeight="bold" mr="20px">
              WERECEIVESMS
            </Span>{' '}
            © {new Date().getFullYear()}
          </Text>
        </Menu>
        <Flex maxWidth="900px" pt="60px" flexDirection="column">
          <H1 mb="20px">About</H1>
          <Text mb="40px">
            WERECEIVESMS with the webs only exclusive temp numbers, you will not find our numbers on any other sites! All our numbers are free, and
            require no signup.
          </Text>
          <Text mb="40px">
            Our temporary phone numbers can be used to protect your privacy when receiving text messages. It can also be used to facilitate online
            purchases, online dating, and to help protect you from identity theft. Verify accounts, receive promotional offers and more.
          </Text>
          <H1 mb="20px">Contact us</H1>
          <Text mb="50px">Email us at wereceivesms@gmail.com for any improvements or help using our services. </Text>
          <div
            dangerouslySetInnerHTML={{
              __html: '<script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>'
            }}
          />
          <div
            class="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="63e3637e18b02ee53b7d62d5"
            data-style-height="24px"
            data-style-width="100%"
            data-theme="light"
            data-min-review-count="10"
            data-style-alignment="center"
          >
            <a href="https://uk.trustpilot.com/review/wereceivesms.com" target="_blank" rel="noopener">
              Trustpilot
            </a>
          </div>
        </Flex>
      </FullHeightContainer>
      <Footer iso2={'en'} />
    </Layout>
  )
}
export default AboutPage
